*{
  margin: 0;
  padding:0;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #184b5e;
  background-image: url('./img/brushed-alum-dark.png');
}
.navbar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1200;
}

.navbar-light .navbar-toggler {
  color: rgb(255, 255, 255);
  border-color: rgba(255, 255, 255, 0.4);
}
.product-imagebox{
  display:flex;
  align-items: center;
  justify-content: center;
}
.product-img{
  
  /* max-height: 100%; */
  max-height: 360px;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 16px;
}
.w-fit{
  width: fit-content !important;
}
.h-fit{
  height: fit-content !important;
}
.modal-cart .MuiBox-root {
  width: 600px;
  background-color: #184b5e;
  background-image: url('./img/diagonal-striped-brick.png');
}
.cartcard img{
  max-height: 100%;
  width: auto;
  height: auto;
  max-width: 100%;
}
.cartcards{
  height: auto;
  height: 80vh;
  overflow-y: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.cartcards::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.cartcards {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.cartamount{
  padding: 8px;
  border-radius: 8px;
}
.footer{
  padding:16px;
  /* position:absolute; */
  /* bottom: 0; */
}
a{
  text-decoration: none;
  color: #fff;
  margin-left: 5px;
}
a:visited{
  text-decoration: none;
  color: #fff;
}
.inventory_card div{
  justify-content: space-between;
}
@media only screen and (max-width: 600px) {
  .modal-cart .MuiBox-root {
    width: 300px;
  }
}
@media only screen and (max-width: 400px) {
  .inventory_card div{
    /* justify-content: space-between; */
    flex-direction: column;
  }
}