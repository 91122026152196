.form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    /* width: 50%; */
}
.form__field {
    font-family: inherit;
    /* width: 100%; */
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 1.1rem;
    color: #000;
    /* padding: 7px 0; */
    background: transparent;
    transition: border-color 0.2s;
}
.form__field::placeholder {
    color: transparent;
}
.form__field:focus {
    /* padding-bottom: 6px; */
    /* font-weight: 700; */
    border-width: 3px;
    border-image: linear-gradient(to right, #11998e, #38ef7d);
    border-image-slice: 1;
}
.form__field:required, .form__field:invalid {
    box-shadow: none;
}